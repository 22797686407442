import clsx from 'clsx';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';

export type Variant =
  | 'primary'
  | 'secondary'
  | 'caution'
  | 'black'
  | 'blue'
  | 'imageEscape'
  | 'imageBasic'
  | 'link';
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  children: ReactNode;
  variant?: Variant;
  buttonSize?: 'none' | 'xs' | 'sm' | 'md' | 'lg';
  noRing?: boolean;
}

export const Button = ({
  children,
  className = '',
  variant = 'primary',
  disabled = false,
  buttonSize = 'md',
  noRing = false,
  ...attributes
}: ButtonProps) => {
  const buttons = {
    primary: clsx(
      'rounded-md text-sm font-semibold shadow',
      disabled
        ? 'bg-disabled border text-gray-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500'
        : 'bg-congress-blue hover:bg-allports text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
    ),
    secondary: clsx(
      'rounded-md bg-white text-sm font-semibold shadow',
      disabled ? 'text-gray-400' : 'text-gray-900 hover:bg-gray-50',
      { 'ring-1 ring-inset ring-gray-300': !noRing },
    ),
    caution: clsx(
      'inline-flex justify-center rounded-md bg-red-600 text-sm font-semibold text-white shadow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
      { 'hover:bg-red-500': !disabled },
    ),
    black: clsx(
      'inline-flex items-center gap-x-1.5 rounded-md bg-black text-sm font-semibold text-white shadow',
      { 'hover:opacity-70': !disabled },
    ),
    blue: clsx(
      'inline-flex justify-center rounded-md bg-indigo-600 text-sm font-semibold text-white shadow focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600',
      { 'hover:bg-indigo-500': !disabled },
    ),
    imageBasic: clsx(
      'inline-flex justify-center rounded-md text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
      { 'hover:bg-gray-200 hover:opacity-70': !disabled },
    ),
    imageEscape: clsx(
      'inline-flex justify-center rounded-md text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
      {
        'hover:bg-red-600 hover:text-white': !disabled,
      },
    ),
    link: clsx('text-indigo-600', { 'hover:text-indigo-900': !disabled }),
  };

  const size = {
    none: '',
    xs: 'px-2 py-1',
    sm: 'px-2.5 py-1.5',
    md: 'px-3 py-2',
    lg: 'px-4 py-3',
  };

  return (
    <button
      className={clsx(buttons[variant], size[buttonSize], className)}
      disabled={disabled}
      {...attributes}
    >
      {children}
    </button>
  );
};
