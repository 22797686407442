import React, { Fragment, ReactNode } from 'react';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';

type Props = {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'caution' | 'checked' | 'yellow caution';
  title: string;
  subTitle: string;
  buttonChildren: ReactNode;
  onClose?: () => void;
};

export const DoubleCheckDialogHeadlessUI = ({
  isOpened,
  setIsOpened,
  type,
  title,
  subTitle,
  buttonChildren,
  onClose,
}: Props) => {
  return (
    <Transition show={isOpened} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[9999]"
        static
        onClose={() => {
          setIsOpened(false);
          onClose();
        }}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-300/75 transition-opacity" />
        </TransitionChild>
        <div className="fixed inset-0 z-10 overflow-y-auto scrollbar-thin">
          <div className="flex h-2/3 items-end justify-center p-4 text-center sm:min-h-full sm:items-center sm:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                // eslint-disable-next-line tailwindcss/migration-from-tailwind-2
                className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 ${
                  subTitle?.length < 80 ? 'sm:max-w-lg' : 'sm:max-w-xl'
                }`}
              >
                <div className="sm:flex sm:items-start">
                  {type === 'checked' ? (
                    <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0 sm:size-10">
                      <CheckIcon
                        className="size-6 text-green-600"
                        aria-hidden="true"
                      />
                    </div>
                  ) : type === 'caution' ? (
                    <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:size-10">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M12.0003 9.00091V12.7509M2.69731 16.1269C1.83131 17.6269 2.91431 19.5009 4.64531 19.5009H19.3553C21.0853 19.5009 22.1683 17.6269 21.3033 16.1269L13.9493 3.37891C13.0833 1.87891 10.9173 1.87891 10.0513 3.37891L2.69731 16.1269ZM12.0003 15.7509H12.0073V15.7589H12.0003V15.7509Z"
                          stroke="#DC2626"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  ) : (
                    type === 'yellow caution' && (
                      <div className="mx-auto flex size-12 shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:size-10">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M12.0003 9.00091V12.7509M2.69731 16.1269C1.83131 17.6269 2.91431 19.5009 4.64531 19.5009H19.3553C21.0853 19.5009 22.1683 17.6269 21.3033 16.1269L13.9493 3.37891C13.0833 1.87891 10.9173 1.87891 10.0513 3.37891L2.69731 16.1269ZM12.0003 15.7509H12.0073V15.7589H12.0003V15.7509Z"
                            stroke="#FACC15"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    )
                  )}
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <DialogTitle
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      {title}
                    </DialogTitle>
                    <div className="mt-2">
                      <p
                        className={`whitespace-pre-wrap text-sm text-gray-500`}
                      >
                        {subTitle}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 flex items-center justify-center gap-6 sm:mt-4 sm:flex sm:flex-row-reverse sm:justify-start sm:gap-3">
                  {buttonChildren}
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
