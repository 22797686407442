import React, { ButtonHTMLAttributes, useState } from 'react';
import { TooltipTop } from '../Tooltip/TooltipTop';
import { TestTypeCategory } from '../../../utils/type';

interface ExecutionSize {
  [key: number]: string;
}

interface ExecutionStyle {
  [key: number]: string;
}

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: number;
  tooltipLocation?: 'right' | 'left';
  testType?: TestTypeCategory;
}

export const Execution = ({
  size = 6,
  tooltipLocation = 'left',
  testType,
  ...props
}: Props) => {
  const [isMouseEnter, setIsMouseEnter] = useState(false);

  const tooltipMessage =
    props.disabled && testType === 'case'
      ? '해당 케이스는 실행 할 수 없습니다.'
      : props.disabled && testType === 'scenario'
        ? '해당 시나리오는 실행 할 수 없습니다.'
        : props.disabled && testType === 'step'
          ? '해당 스텝은 실행 할 수 없습니다.'
          : 'Execution';

  const executionSize: ExecutionSize = {
    5: 'w-5 h-5',
    6: 'w-6 h-6',
  };

  const executionStyle: ExecutionStyle = props.disabled
    ? {
        5: 'border-r border-r-gray-200 pr-2 text-gray-400',
        6: 'p-1 text-gray-400',
      }
    : {
        5: 'border-r border-r-gray-200 pr-2 hover:text-congress-blue',
        6: 'p-1 text-gray-900 hover:text-congress-blue',
      };
  return props.disabled ? (
    <>
      <button
        type="button"
        className={`relative ${executionStyle[size]} mr-3`}
        {...props}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className={executionSize[size]}
          onMouseEnter={() => props.disabled && setIsMouseEnter(true)}
          onMouseLeave={() => props.disabled && setIsMouseEnter(false)}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
          />
        </svg>
        {isMouseEnter && (
          <TooltipTop
            positionClass={`icon-description bottom-full min-w-max mb-2 ${tooltipLocation === 'right' ? '-right-20' : '-left-3'} after:left-[50%]`}
          >
            {tooltipMessage}
          </TooltipTop>
        )}
      </button>
    </>
  ) : (
    <button
      type="button"
      className={`${executionStyle[size]} relative mr-3
      `}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className={executionSize[size]}
        onMouseEnter={() => !props.disabled && setIsMouseEnter(true)}
        onMouseLeave={() => !props.disabled && setIsMouseEnter(false)}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
        />
      </svg>
      {isMouseEnter && (
        <TooltipTop
          positionClass={`icon-description bottom-full min-w-max mb-2 ${tooltipLocation === 'right' ? '-right-5' : ''} after:left-[50%]`}
        >
          {tooltipMessage}
        </TooltipTop>
      )}
    </button>
  );
};
