import clsx from 'clsx';
import React from 'react';

interface TooltipProps {
  children: React.ReactNode;
  positionClass: string;
  customStyle?: React.CSSProperties;
}

// positionClass에는 left- bottom- 로 사용 필요
export const TooltipTop = ({
  children,
  positionClass,
  customStyle,
}: TooltipProps) => {
  return (
    <div className={clsx('tooltip-top', positionClass)} style={customStyle}>
      {children}
    </div>
  );
};
