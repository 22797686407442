import React from 'react';
import { Checkbox } from '../shared/Checkbox/Checkbox';
import clsx from 'clsx';

type Props = {
  hasCheckBox?: boolean;
  hasBorder?: boolean;
  style?: string;
};

export const ImportTestStepTableSkeleton = ({
  style,
  hasCheckBox = true,
  hasBorder = false,
}: Props) => {
  return (
    <div className="max-h-72">
      <table
        className={clsx(
          'w-full table-fixed border-separate border-spacing-0',
          hasBorder && 'rounded-lg border',
          style,
        )}
      >
        <thead className="sticky top-0 border-b border-gray-300 bg-white text-left text-sm">
          <tr className="h-12 border-b border-gray-300 px-3 py-3.5">
            {hasCheckBox && (
              <th className="w-16 border-b border-gray-300 p-3">
                <Checkbox disabled />
              </th>
            )}
            <th className="w-20 border-b border-gray-300 p-3 font-medium">#</th>
            <th className="w-2/6 border-b border-gray-300 p-3 font-medium">
              Command
            </th>
            <th className="border-b border-gray-300 p-3 font-medium">
              Description
            </th>
          </tr>
        </thead>
        <tbody className="border-b border-gray-300 text-sm">
          {Array.from({ length: 4 }).map((_, index) => (
            <tr key={index} className="h-12 px-3 py-3.5">
              {hasCheckBox && (
                <td className="border-b border-gray-300 p-3">
                  <div className="size-4 animate-pulse rounded-sm bg-gray-300"></div>
                </td>
              )}
              <td className="border-b border-gray-300 p-3">
                <div className="h-4 w-8 animate-pulse rounded bg-gray-300"></div>
              </td>
              <td className="border-b border-gray-300 p-3">
                <div className="h-4 w-20 animate-pulse rounded bg-gray-300"></div>
              </td>
              <td className="border-b border-gray-300 p-3">
                <div className="h-4 w-full animate-pulse rounded bg-gray-300"></div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
