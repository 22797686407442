import React from 'react';

export const GroupContentSkeleton = () => {
  return (
    <>
      {Array.from({ length: 3 }).map((_, index) => (
        <div
          key={index}
          className="relative animate-pulse rounded-lg border border-gray-200 shadow-sm"
        >
          <div className="rounded-t-md border-b bg-gray-400/10 p-3 text-gray-800">
            <div className="mb-2 h-6 w-3/4 rounded bg-gray-300"></div>
            <div className="ml-auto size-6 rounded-full bg-gray-300"></div>
          </div>
          <div className="p-5">
            <div className="mb-2 h-4 w-full rounded bg-gray-300"></div>
            <div className="mb-1 flex justify-between border-t pt-2">
              <div className="h-4 w-1/2 rounded bg-gray-300"></div>
              <div className="h-4 w-1/4 rounded bg-gray-300"></div>
            </div>
            <div className="flex justify-between">
              <div className="h-4 w-1/2 rounded bg-gray-300"></div>
              <div className="h-4 w-1/4 rounded bg-gray-300"></div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};
