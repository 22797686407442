import React, { Fragment } from 'react';
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

interface ListItem {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface ListBoxHeadlessInControllerProps {
  value: string | number;
  buttonValue: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: any[]) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  lists: (ListItem | any)[];
  valueToSave: string;
  savedType?: 'string' | 'number' | undefined;
  valueToShow: string;
  defaultValue?: string | number;
  disabled?: boolean;
  onClickButton?: React.MouseEventHandler<HTMLButtonElement>;
  onClickOption?: React.MouseEventHandler<HTMLDivElement>;
  noListMessage?: string;
}

export const ListBoxHeadlessInController = ({
  defaultValue,
  buttonValue,
  onChange,
  lists,
  valueToSave,
  valueToShow,
  savedType,
  onClickButton,
  onClickOption,
  disabled = false,
  value = '',
  noListMessage = 'Not Founded',
}: ListBoxHeadlessInControllerProps) => {
  return (
    <Listbox
      value={value}
      onChange={onChange}
      disabled={disabled}
      defaultValue={defaultValue}
    >
      {({ open }) => (
        <>
          <div className="relative">
            <ListboxButton
              className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
              onClick={onClickButton}
            >
              <span className="block truncate">{buttonValue}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="size-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </ListboxButton>
            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 scrollbar-thin focus:outline-none sm:text-sm">
                {lists?.length === 0 ? (
                  <li className="relative cursor-default select-none list-none px-4 py-2 text-gray-700">
                    {noListMessage}
                  </li>
                ) : (
                  lists?.map((value, index) => (
                    <ListboxOption
                      key={index}
                      className="group relative cursor-default select-none py-2 pl-3 pr-9 data-[focus]:bg-indigo-600"
                      value={
                        savedType === 'string'
                          ? value[valueToSave].toString()
                          : savedType === 'number'
                            ? Number(value[valueToSave])
                            : value[valueToSave]
                      }
                      onClick={onClickOption}
                      disabled={value.disabled}
                    >
                      <span className="block break-all font-normal group-data-[selected]:font-semibold group-data-[disabled]:text-gray-400 group-data-[focus]:text-white">
                        {value[valueToShow]}
                      </span>
                      <span className="invisible absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[selected]:visible group-data-[focus]:text-white">
                        <CheckIcon className="size-5" aria-hidden="true" />
                      </span>
                    </ListboxOption>
                  ))
                )}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
};
